var JQTE = this;

function init(){
    $(document).ready(function(){
        $('.jqte-1').jqte({rule: false});
    })
}

function start(val, opts) {
    setTimeout(() => {
        $('.jqte-1').jqte({rule: false});
        if(val){
            $(".jqte-1").jqteVal(val);
        }
        if(opts && opts.id){
            $('.jqte_editor')[0].id = opts.id;
        }
    }, 100);
}

function getValue(id){
    return $(id).val()
}

function setValue(id, val){
    return $(id).jqteVal(val);
}